import React from 'react'
import Link from 'gatsby-link'

import './style.scss'
import Layout from 'layouts/Page'
import SEO from 'components/SEO/SEO'

const AboutPage = () => (
  <div id="about-page">
    <div className="content-wrap">
      <section className="about-page page-title">
        <div className="container">
          <h2>Our Manifesto</h2>

          <div className="manifesto-doc">
            <p>
              We're a team of engineers and problem solvers with serious drive
              and a simple idea:{' '}
            </p>

            <h3>Experience more through connecting and sharing.</h3>

            <p>
              We believe that by making communities more communal, fairer, and
              connected, we can all live life more fully. Whether it's renting a
              mountain bike for a spontaneous weekend getaway, or trying out
              something new that excites you, we're here to help make it happen.
            </p>
            <p>
              We're building technology for a world that we believe in. What
              excites you about tomorrow? Let us know: team@borrowmellow.com
            </p>

            <p style={{ marginBottom: 5 }}>Cheers,</p>
            <p> Mellow Team</p>
          </div>
        </div>
        <svg
          className="about-cloud"
          xmlns="http://www.w3.org/2000/svg"
          width="1360"
          height="958"
          viewBox="0 0 1360 958"
        >
          <path
            fill="#DEE1EA"
            fillRule="evenodd"
            d="M856.032,43.233 C763.56,51.354 755.995,47.037 702.004,24.653 C665.707,8.809 625.635,0 583.5,0 C457.462,0 349.808,78.649 306.897,189.541 C272.98,271.849 260.919,283.503 181.846,316.876 C75.359,358.829 0,462.613 0,584 C0,742.506 128.494,871 287,871 C328.535,871 368.001,862.164 403.643,846.289 C491.057,809.571 522.617,802.872 651.485,883.891 C723.395,930.758 809.265,958 901.5,958 C1154.723,958 1360,752.723 1360,499.5 C1360,246.277 1154.723,41 901.5,41 C886.155,41 870.989,41.76 856.032,43.233 Z"
            opacity=".2"
          />
        </svg>
      </section>
    </div>
  </div>
)

export default ({ location }) => (
  <Layout navbar="main" location={location}>
    <SEO title="About Mellow" />
    <AboutPage />
  </Layout>
)
